import React, {useEffect, useState} from "react";
import "../App.css";
import "./contatti.css"
import Navbar from "../components/navbar/Navbar";
import { FaPhone } from "react-icons/fa";
import {GoMail} from "react-icons/go";
import {BiMap} from "react-icons/bi";
import office from "../images/ufficio.png";

function Contatti() {

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )
    useEffect(() => {
        window
            .matchMedia("(min-width: 768px)")
            .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    return (
        <div className="App">
            <Navbar></Navbar>
            <iframe src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2846.9574131577706!2d11.3796244155246!3d44.47504567910179!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477e2b226386f69f%3A0xf7873948843125c2!2sVia%20Udine%2C%2040139%20Bologna%20BO!5e0!3m2!1sit!2sit!4v1637603507355!5m2!1sit!2sit"}
                    width="100%" height="360" className={"map"}>
            </iframe>
            <div className="contact">
                <div className={"contactsContainer"}>
                    <div className={"horizontalContainer"}>
                        <h2 className={matches ? "" : "contactMin"}>Contatti</h2>
                        <div className={matches ? "contactsBox" : "contactsBoxMin2"}>
                            <BiMap className={"icon"}></BiMap>
                            <h4>Indirizzo</h4>
                            <a><h4>Via Udine 3/2</h4></a>
                        </div>
                        <a href={"tel:3397245757"}>
                        <div className={matches ? "contactsBox" : "contactsBoxMin2"}>
                            <FaPhone className={"icon"}></FaPhone>
                            <h4>Telefono</h4>
                            <h4>3397245757</h4>
                        </div>
                        </a>
                        <a href={"mailto:lorena.ceccacci@gmail.com"}>
                        <div className={matches ? "contactsBox" : "contactsBoxMin2"}>
                            <GoMail className={"icon"}></GoMail>
                            <h4>Email</h4>
                            <h4 className={matches ? "" : ""}>lorena.ceccacci @gmail.com</h4>
                        </div>
                        </a>

                    </div>
                    <div className={"horizontalContainer"}>
                        <h2 className={matches ? "" : "contactMin"}>Sede</h2>
                        <img src={office} className={matches ? "officeImage" : "officeImageMin"} />
                        <div className={"contactsBoxEmpty"}>
                        </div>
                    </div>
                </div>
            </div>
            <footer className={"footer"}>
                <p>© Lorena Ceccacci immobiliare, All Rights Reserved</p>
            </footer>
        </div>
    );
}

export default Contatti;