import React from "react";
import logo from "./images/interior.jpg";
import "./App.css";
import Navbar from "./components/navbar/Navbar";
import {BrowserRouter as Router, Routes, Route, Navigate, BrowserRouter} from "react-router-dom";
import Immobili from "./Pages/Immobili";
import Homepage from "./Pages/Homepage";
import {DefaultContext} from "react-icons";
import Servizi from "./Pages/Servizi";
import Contatti from "./Pages/Contatti";
import Login from "./Pages/Login";
import Chisiamo from "./Pages/Chisiamo";
import Amministrazione from "./Pages/Amministrazione";
import Vendite from "./Pages/Vendite";

function App() {
  const [data, setData] = React.useState(null);
  /*
  React.useEffect(() => {
    fetch("/api")
        .then((res) => res.json())
        .then((data) => setData(data.message));
  }, []);
   */

  const addHouse = async () => {
    const request = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "via": "canova 10", "tipo": "affitto" })
    };
    const response = await fetch('/add', request);
    const myJson = await response.json(); //extract JSON from the http response
    return myJson
  }

  const removeHouse = async () => {
    const request = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "via": "spartaco 10", "tipo": "affitto" })
    };
    const response = await fetch('/remove', request);
    const myJson = await response.json(); //extract JSON from the http response
    return myJson
  }

  let DEFAULT_ROUTE_PAGE = <Homepage />

  return (
      <BrowserRouter>
        <Routes>
          <Route index element={DEFAULT_ROUTE_PAGE} />
          <Route path="/home" element={<Homepage />}/>
          <Route path="/immobili" element={<Immobili />} />
          <Route path="/servizi" element={<Servizi />}/>
          <Route path="/chisiamo" element={<Chisiamo />}/>
          <Route path="/contatti" element={<Contatti />} />
          <Route path="/vendite" element={<Vendite />} />
          <Route path="/login" element={<Login />} />
          <Route path="/amministrazione" element={<Amministrazione />} />
        </Routes>
      </BrowserRouter>
      /*
      <>
        <Router>
          <Routes>
            <Navigate to={"/home"}/>
            <Route path="/home" element={<Homepage />}/>
            <Route path="/immobili" element={<Immobili />} />
          </Routes>
        </Router>

      </>

       */
  );
}

export default App;

