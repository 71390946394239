import React, {useEffect, useState} from "react";
import logo from "../images/service.jpg";
import "../App.css";
import Navbar from "../components/navbar/Navbar";
import {BsFillCameraFill} from "react-icons/bs";
import {HiOutlineDocumentText} from "react-icons/hi";
import "./servizi.css"
import {MdAssessment} from "react-icons/md";
import {MdModeEditOutline} from "react-icons/md";
import {AiOutlineSearch} from "react-icons/ai";
import {MdOutlineManageAccounts} from "react-icons/md";
import {GiPlayerNext} from "react-icons/gi";
import {FaFileContract} from "react-icons/fa";

function Servizi() {

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )
    useEffect(() => {
        window
            .matchMedia("(min-width: 768px)")
            .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    return (
        <div className="App">
            <Navbar></Navbar>
            <div className="container">
                <img src={logo} className={"serviceimage"} />
                <div className="centered"><h1>SERVIZI</h1></div>
            </div>
            <div className="service">
                <div className={"contactsContainer"}>
                    <div className="chisiamo">
                        <h1 className={"chisiamoH"}>AIUTO PER COMPRARE CASA? ECCO I MIEI CONSIGLI</h1>
                        <p className={"parChisiamo"}>Per chi si accinge ad acquistare casa per la prima volta, ma anche per chi ha fatto un precedente acquisto diversi anni fa, non è semplice capire come orientarsi e soprattutto a chi rivolgersi. </p>
                        <p className={"parChisiamo"}>Potendo disporre di un importante patrimonio certo ci sono diversi professionisti e figure che in questi ultimi anni si sono affacciate nel mercato immobiliare che è diventato molto più complesso grazie e giustamente a una maggior attenzione alla documentazione edilizia richiesta dal notaio.</p>
                        <p className={"parChisiamo"}>Per il normale acquirente è importante affidarsi ad un consulente/agente immobiliare capace di aiutarti a tutto tondo, capace di avvalersi lui stesso di tutti i professionisti che servono senza troppi aumenti di costi.</p>
                        <p className={"parChisiamo"}>Il tuo agente immobiliare deve non solo essere interessato alla vendita e accompagnarti a visitare casa, ma deve aiutarti a trovare quello che realmente stai cercando e soprattutto deve venderti un oggetto valido per il tuo budget e per la zona che vuoi. Insomma farti fare un buon acquisto.</p>
                        <p className={"parChisiamo"}>Deve portarti un immobile con una situazione urbanistica, edilizia e catastale approfondita e chiara.</p>
                        <p className={"parChisiamo"}>Deve essere in grado di fornirti esperti in ristrutturazione edilizia che possano aiutarti a valutare la casa con obbiettività e capire se ha le potenzialità che ti interessano.</p>
                        <p className={"parChisiamo"}> Deve fornirti un esperto in pratiche edilizie da consultare in caso di dubbi prima della firma del compromesso.</p>
                        <p className={"parChisiamo"}>Da ultimo un notaio se non si sa a chi rivolgersi.</p>
                        <div className={"contactsContainer"}>
                        </div>
                    </div>
                </div>
            </div>
            <footer className={"footer"}>
                <p>© Lorena Ceccacci immobiliare, All Rights Reserved</p>
            </footer>
        </div>
    );
}

export default Servizi;

