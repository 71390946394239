import React, {useState} from "react";
import "../App.css";
import "./amministrazione.css"
import Navbar from "../components/navbar/Navbar";
import {AiFillCloseSquare} from "react-icons/ai";

function Amministrazione() {
    const [json, setJson] = useState<any[]>([])

    function reload() {
        fetch("/api")
            .then((res) => res.json())
            .then((data) => alert(data.message))
    }
    /*
    React.useEffect(() => {
        fetch("/api")
            .then((res) => res.json())
            .then((data) => setJson(data.message))
    }, [json]);
     */

    const [via, setVia] = React.useState<string>("");
    const onChangeVia = event => {
        setVia(event.target.value);
    };
    const [tipo, setTipo] = React.useState<string>("");
    const onChangeTipo = event => {
        setTipo(event.target.value);
    };
    const [link, setLink] = React.useState<string>("");
    const onChangeLink = event => {
        setLink(event.target.value);
    };
    const [image, setImage] = React.useState<string>("");
    const onChangeImage = event => {
        setImage(event.target.value);
    };
    const [price, setPrice] = React.useState<string>("");
    const onChangePrice = event => {
        setPrice(event.target.value);
    };

    const addHouse = async (address: string, type: string, imlink: string, imageLink: string, priceVal: string) => {
        const request = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "via": address, "tipo": type, "link": imlink, "image": imageLink, "price": priceVal})
        };
        const response = await fetch('/add', request);
        const myJson = await response.json(); //extract JSON from the http response
        return myJson
        alert("Aggiunto")
        fetch("/api")
            .then((res) => res.json())
            .then((data) => setJson(data.message))
    }

    const removeHouse = async (via: string, tipo: string) => {
        const request = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "via": via, "tipo": tipo })
        };
        const response = await fetch('/remove', request);
        const myJson = await response.json(); //extract JSON from the http response
        return myJson
    }


    return (
        <div className="App">
            <Navbar></Navbar>

            <div className="content">
                <div className={"adminContainer"}>
                    <div className={"contactsBoxEmpty"}>
                    </div>
                    {json && json.map((item) =>
                        <a>
                            <div className={"adminContactsBox"}>
                                <AiFillCloseSquare onClick={() => removeHouse(item.via, item.tipo)} className={"close"} size={70} color={"red"}></AiFillCloseSquare>
                                <img className={"houseImage"} src={item.image}/>
                                <h4>{item.via}</h4>
                                <a><p>{item.tipo}</p></a>
                            </div>
                        </a>)
                    }
                    <div className={"contactsBoxEmpty"}>
                    </div>
                </div>
            </div>
            <div className={"login"}>
                <h2 className={"logTitle"}>Aggiungi immobile</h2>
                <h4 className={"user"}>Via</h4>
                <input type="text"
                       placeholder="Via"
                       className={"text"}
                       onChange={onChangeVia}
                       value={via}>
                </input>
                <h4 className={"user"}>Tipo</h4>
                <input type={"text"}
                       placeholder={"Vendita/Affitto"}
                       className={"text"}
                       onChange={onChangeTipo}
                       value={tipo}>
                </input>
                <h4 className={"user"}>Link</h4>
                <input type="text"
                       placeholder="Link"
                       className={"text"}
                       onChange={onChangeLink}
                       value={link}>
                </input>
                <h4 className={"user"}>Immagine</h4>
                <input type={"text"}
                       placeholder={"Immagine"}
                       className={"text"}
                       onChange={onChangeImage}
                       value={image}>
                </input>
                <h4 className={"user"}>Prezzo</h4>
                <input type="text"
                       placeholder="Prezzo"
                       className={"text"}
                       onChange={onChangePrice}
                       value={price}>
                </input>
                <button onClick={() => addHouse(via, tipo, link, image, price)} className={"logbutton"} >Aggiungi</button>
                <button onClick={() => reload()} className={"logbutton"} >aggiorna</button>
            </div>
            <footer className={"footer"}>
                <p>© Lorena Ceccacci immobiliare, All Rights Reserved</p>
            </footer>
        </div>
    );
}

export default Amministrazione;