import React from "react";
import "../App.css";
import "./login.css"
import Navbar from "../components/navbar/Navbar";

function Login() {
    /*
    const [data, setData] = React.useState(null);
    React.useEffect(() => {
        fetch("/api")
            .then((res) => res.json())
            .then((data) => setData(data.message[1].via));
    }, []);
     */

    const [username, setUsername] = React.useState<string>("");
    const onChangeUser = event => {
        setUsername(event.target.value);
    };
    const [password, setPassword] = React.useState<string>("");
    const onChangePass = event => {
        setPassword(event.target.value);
    };

    function navigate(page: any) {
        window.location.href = `${page}`;
    }

    function checkLogin() {
        if (username == "Lorena" && password == "password") {
            navigate("amministrazione")
        }
    }

    return (
        <div className="App">
            <Navbar></Navbar>
            <header className={"App-header"}>
            </header>
            <div className={"firstLog"}>
                <div>
                    <div className={"login"}>
                        <h2 className={"logTitle"}>Login Here</h2>
                        <h4 className={"user"}>Username</h4>
                        <input type="text"
                               placeholder="Username"
                               className={"text"}
                               name="usern"
                               onChange={onChangeUser}
                               value={username}>
                        </input>
                        <h4 className={"user"}>Password</h4>
                        <input type={"password"}
                               placeholder={"Password"}
                               className={"text"}
                               name="passw"
                               onChange={onChangePass}
                               value={password}>
                        </input>
                        <button onClick={() => checkLogin()} className={"logbutton"} >Login</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;

