import React, {useEffect, useState} from "react";
import logo from "../images/interior.jpg";
import "../App.css";
import "./homepage.css"
import Navbar from "../components/navbar/Navbar";
import {BsHouseFill} from "react-icons/bs";
import {BsHouse} from "react-icons/bs";
import {MdAssessment} from "react-icons/md";
import { useNavigate } from "react-router-dom";

function Homepage() {
    /*
    const [data, setData] = React.useState(null);
    React.useEffect(() => {
        fetch("/api")
            .then((res) => res.json())
            .then((data) => setData(data.message[1].via));
    }, []);
     */
    function navigate(page: any, type?: any) {
        window.location.href = `${page}`;
    }
    const navigation = useNavigate();
    function handleClick(type) {
        navigation("/vendite", {state:{id:type}});
    }
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )
    useEffect(() => {
        window
            .matchMedia("(min-width: 768px)")
            .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    return (
        <div className="App">
            <Navbar></Navbar>
            <header className="App-header">
            </header>
            <img src={logo} className={matches ? "homepageimage" : "homepageimageMin"} />
             <div className={"content"}>
                <h1 className={matches ? "" : "titleMin"}>Consulenza immobiliare per ogni tua esigenza</h1>
            </div>
            <div className={"contactsContainer"}>
                <div className={"contactsBoxEmpty"}>
                </div>
                <div className={matches ? "contactsBox" : "contactsBoxMin"} onClick={() => handleClick(1)}>
                    <BsHouseFill className={"icon"}></BsHouseFill>
                    <h3>VENDITE</h3>
                </div>
                <div className={matches ? "contactsBox" : "contactsBoxMin"} onClick={() => handleClick(2)}>
                    <BsHouse className={"icon"}></BsHouse>
                    <h3>AFFITTI</h3>
                </div>
                <div className={matches ? "contactsBox" : "contactsBoxMinX"} onClick={() => navigate("servizi")}>
                    <MdAssessment className={"icon"}></MdAssessment>
                    <h3>VALUTAZIONE IMMOBILI</h3>
                </div>
                <div className={"contactsBoxEmpty"}>
                </div>
            </div>
            <footer className={"footer"}>
                <p>© Lorena Ceccacci immobiliare, All Rights Reserved</p>
            </footer>
        </div>
    );
}

export default Homepage;

