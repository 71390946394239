import React, {useEffect, useState} from "react";
import logo from "../images/chisiamo.jpeg";
import "../App.css";
import Navbar from "../components/navbar/Navbar";
import "./chisiamo.css"

function Chisiamo() {

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )
    useEffect(() => {
        window
            .matchMedia("(min-width: 768px)")
            .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    return (
        <div className="App">
            <Navbar></Navbar>
            <div className={"container"}>
                <img src={logo} className={matches ? "serviceimage" : "serviceimageMin"} />
                <div className="centered"><h1>CHI SIAMO</h1></div>
            </div>
            <div className="chisiamo">
                <h1 className={"chisiamoH"}>Chi è Lorena Ceccacci</h1>
                <p className={"parChisiamo"}>Svolgo questa attività dal 1992 e ho aperto la mia Agenzia nel 2012.
                Ho riversato nel mio lavoro ciò che mi contraddistingue semplicità, chiarezza e determinazione.
                Tutto ciò arricchito nel tempo dall’esperienza  mi permette di svolgere al meglio il mio lavoro,
                di avere un ottima rete di rapporti sia con colleghi che con altri professionisti
                al fine  di facilitare tutti i tipi di transazioni immobiliari per poter dare ai clienti finali i risultati migliori.</p>
                <div className={"contactsContainer"}>

                </div>
            </div>
            <footer className={"footer"}>
                <p>© Lorena Ceccacci immobiliare, All Rights Reserved</p>
            </footer>
        </div>
    );
}

export default Chisiamo;

