import React, {useEffect, useState} from "react";
import logo from "../images/service.jpg";
import "../App.css";
import Navbar from "../components/navbar/Navbar";
import {BsFillCameraFill} from "react-icons/bs";
import {HiOutlineDocumentText} from "react-icons/hi";
import "./servizi.css"
import "./vendite.css"
import {MdAssessment} from "react-icons/md";
import {MdModeEditOutline} from "react-icons/md";
import {AiOutlineSearch} from "react-icons/ai";
import {MdOutlineManageAccounts} from "react-icons/md";
import {GiPlayerNext} from "react-icons/gi";
import {FaFileContract} from "react-icons/fa";
import {useLocation} from 'react-router-dom';
import {GiDeliveryDrone} from "react-icons/gi";
import {BsCameraVideo} from "react-icons/bs";
import {MdOutlineRealEstateAgent} from "react-icons/md";
import {MdAutoFixHigh} from "react-icons/md";


function Vendite() {

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
        window
            .matchMedia("(min-width: 768px)")
            .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    return (
        <div className="App">
            <Navbar></Navbar>
            <div className="container">
                <img src={logo} className={"serviceimage"} />
                <div className="centered"><h1>{location.state.id === 1 ? 'VENDITA' : 'AFFITTO'}</h1></div>
            </div>
            <div className="service">
                    {location.state.id === 1 ?
                        <div className={"contactsContainer"}>
                        <div className={matches ? "servicesBox" : "servicesBoxMin"}>
                            <div className={"serviceBoxPar"}>
                                <p className={"par"}><MdAssessment className={matches ? "icon2" : "icon2Min"}></MdAssessment>&nbsp;Valutazione di mercato</p>
                                <p className={"par"}><HiOutlineDocumentText className={matches ? "icon2" : "icon2Max"}></HiOutlineDocumentText>&nbsp;Pratiche e documenti necessari per arrivare al rogito</p>
                                <p className={"par"}><MdModeEditOutline className={matches ? "icon2" : "icon2"}></MdModeEditOutline>&nbsp;Assistenza fino alla data del rogito</p>
                                <p className={"par"}>&nbsp;</p>
                                <p className={"par"}>&nbsp;</p>
                                <p className={"par"}>&nbsp;</p>
                            </div>
                        </div>
                            <div className={matches ? "servicesBox2" : "servicesBox2Min"}>
                                <div className={"serviceBoxPar"}>
                                    <p className={"par"}><BsFillCameraFill className={matches ? "icon2" : "icon2Min"}></BsFillCameraFill>&nbsp;Book fotografico professionle</p>
                                    <p className={"par"}><BsCameraVideo className={matches ? "icon2" : "icon2Min3"}></BsCameraVideo>&nbsp;&nbsp;&nbsp;Virtual tour</p>
                                    <p className={"par"}><MdOutlineRealEstateAgent className={matches ? "icon2" : "icon2Max"}></MdOutlineRealEstateAgent>&nbsp;Extra visibilità sui portali immobiliari nazionali</p>
                                    <p className={"par"}><GiDeliveryDrone className={matches ? "icon2" : "icon2Min4"}></GiDeliveryDrone>&nbsp;Video con drone</p>
                                    <p className={"par"}><MdAutoFixHigh className={matches ? "icon2" : "icon2Min4"}></MdAutoFixHigh>&nbsp;&nbsp;Home staging</p>
                                </div>
                            </div>
                        </div>

                        :
                        <div className={"contactsContainer"}>
                        <div className={matches ? "servicesBox2" : "servicesBox2Min"}>
                            <div className={matches ? "serviceBoxPar2" : "serviceBoxPar"}>
                                <p className={"par2"}><AiOutlineSearch className={matches ? "icon2" : "icon2Max"}></AiOutlineSearch>&nbsp;Ricerca del conduttore designato e selezionato</p>
                                <p className={"par2"}><FaFileContract className={matches ? "icon2" : "icon2"}></FaFileContract>&nbsp;Redazione e registrazione del contratto</p>
                                <p className={"par2"}><MdOutlineManageAccounts className={"icon2"}></MdOutlineManageAccounts>&nbsp;&nbsp;Gestione del conduttore nel tempo</p>
                                <p className={"par2"}><GiPlayerNext className={"icon2"}></GiPlayerNext>&nbsp;&nbsp;Rinnovi, subentri e proroghe</p>
                            </div>
                        </div>
                        </div>
                    }

            </div>
            <footer className={"footer"}>
                <p>© Lorena Ceccacci immobiliare, All Rights Reserved</p>
            </footer>
        </div>
    );
}

export default Vendite;

