import './navbar.css'
import logo from '../../images/logo2.jpg';
import { FaRegUserCircle } from "react-icons/fa";
import {Route, Routes, useNavigate} from "react-router-dom"
import Immobili from "../../Pages/Immobili";
import React, {useEffect, useState} from "react";

export default function Navbar() {
    //const navigate = useNavigate();
    function navigate(page: any) {
        window.location.href = `${page}`;
    }
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )
    useEffect(() => {
        window
            .matchMedia("(min-width: 768px)")
            .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    return (
        <div className={"colorwhite"}>
            <div className={"head"}>

                <img src={logo} className={matches ? "logo" : "logoMin"}/>
                {/*
                    matches ?
                    <button onClick={() => navigate("login")} className={"log"}><FaRegUserCircle className={"iconLogin"}></FaRegUserCircle>{"log in"}</button>
                    : <div></div>
                    */
                }
            </div>
            <nav className={matches ? "navbar" : "navbarMin"}>
                <div className={"navdiv"}>
                    <a className={matches ? "responsiveMax" : "responsiveMin"} onClick={() => navigate("home")}>HOME</a>
                    <a className={matches ? "responsiveMax" : "responsiveMin"} onClick={() => navigate("immobili")}>IMMOBILI</a>
                    <a className={matches ? "responsiveMax" : "responsiveMin"} onClick={() => navigate("servizi")}>SERVIZI</a>
                    <a className={matches ? "responsiveMax" : "responsiveMin"} onClick={() => navigate("chisiamo")}>CHI SIAMO</a>
                    <a className={matches ? "responsiveMax" : "responsiveMin"} onClick={() => navigate("contatti")}>CONTATTI</a>
                </div>
            </nav>
        </div>
        /*
        <div>
            <div className={"head"}>
                <div></div>
                <img src={logo} className={"logo"}/>
                <button className={"log"}><FaRegUserCircle className={"icon"}></FaRegUserCircle> log in</button>
            </div>
            <nav className="navbar">
                <div>
                    <a className="active" onClick={() => navigate("home", { replace: true })}>HOME</a>
                    <a onClick={() => navigate("immobili", { replace: true })}>IMMOBILI</a>
                    <a onClick={() => navigate("servizi", { replace: true })}>SERVIZI</a>
                    <a onClick={() => navigate("contatti", { replace: true })}>CONTATTI</a>
                </div>
            </nav>
        </div>

         */
    )
}